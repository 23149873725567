<template>
  <v-container
    style="background-color:#fafafa; max-width:350px;"
    class="pa-9"
    rounded-xl
  >
    <v-row>
      <v-col cols="12" class="text-center">
        <h1>
          Inicia Sesión
        </h1>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Correo"
          v-model="correo"
          prepend-icon="mdi-email"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="contrasena"
          label="Contraseña"
          prepend-icon="mdi-lock"
          type="password"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          large
          block
          rounded
          :loading="cargando"
          :disabled="cargando"
          @click="login"
          class="white--text"
          style="font-family: 'Open Sans', sans-serif;"
          >Login</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PostService from "../../PostService";
export default {
  name: "garantias",
  data: () => ({
    correo: "",
    contrasena: "",
    cargando: false
  }),
  created() {
    if (this.$store.getters.token !== "")
      return this.$router.push({ name: "admin-garantias" });
  },
  methods: {
    async login() {
      this.cargando = true;
      try {
        const respuestaLogin = await PostService.login({
          user: this.correo,
          pwd: this.contrasena
        });
        this.$store.dispatch(
          "setNewToken",
          // respuestaLogin.headers["auth-token"]
          respuestaLogin.data.token
        );
        this.$store.dispatch("setUser", {
          correo: respuestaLogin.data.correo,
          nombre: respuestaLogin.data.username
        });
        this.$store.dispatch("setSnackBarProps", {
          mensaje: "¡Bienvenido " + respuestaLogin.data.username + "!",
          mostrar: true,
          color: "success"
        });
        this.$router.push({ name: "admin-garantias" });
      } catch (error) {
        this.$store.dispatch("setSnackBarProps", {
          mensaje: "Usuario o contraseña incorrectos. Intente de nuevo",
          mostrar: true,
          color: "error"
        });
      }
      this.cargando = false;
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
h1 {
  font-family: "Open Sans", sans-serif;
  color: rgb(59, 59, 59);
  font-size: 38px;
}
h2 {
  font-family: "Open Sans", sans-serif;
  color: rgb(92, 92, 92);
  font-size: 17px;
}
</style>
